<template>
    <div class="mt-2">
        <app-view-table :isTopBar="false" :isRowHover="true" :filters.sync="filters" :items="items" :fields="fields" :customColumns="customColumns" v-on:onRowSelected="goToShiftProfile" class="table-selectable" :key="refresh">
           

            <template v-slot:thead-top>
                <b-th class="text-center" colspan="3">{{trans('planning-fw-data',173)}}</b-th> 
                <b-th class="text-center app-table-bg-light" colspan="8">{{trans('planning-service-data',173)}}</b-th>
                <b-th class="text-center" colspan="5"></b-th>
            </template>

            <template v-slot:function_person="cell">
                <span class="app-ellips" :title="cell.data.item.function_person_full" > {{cell.data.item.function_person}} </span>
            </template>

            <template v-slot:client_location_name="cell">
                <span class="app-ellips" :title="cell.data.item.client_location_name_full" > {{cell.data.item.client_location_name}} </span>
            </template>

            <template v-slot:client_department_name="cell">
                <span class="app-ellips" :title="cell.data.item.client_department_name_full" > {{cell.data.item.client_department_name}} </span>
            </template>

            <template v-slot:function_shift="cell">
                <span class="app-ellips" :title="cell.data.item.function_shift_full" > {{cell.data.item.function_shift}} </span>
            </template>

            <template v-slot:menu-items="cell"> 
                <b-dropdown-item :to="{ name: 'planning-shifts-profile', params: { id_shift_data_main: cell.data.item.id_shift_data_main }}">{{trans('to-the-services', 200)}}</b-dropdown-item>

                <!--ADD PERSON-->
                <b-dropdown-item v-if="[1, 3, 11].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="addPerson(cell.data.item)">{{trans('cp-register-flexworker',189)}}</b-dropdown-item>

                <!--SUGGEST PERSON TO CLIENT-->
                <b-dropdown-item v-if="cell.data.item.id_function_data_main != null && (cell.data.item.id_shift_const_status == 3 || cell.data.item.id_shift_const_status == 11) && [1].includes(cell.data.item.id_applicant_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="suggestClient(cell.data.item)">{{trans('sh-offer-to-client',220)}}</b-dropdown-item>
                
                <!--CONFIRM PERSON-->
                <b-dropdown-item v-if="[4, 15].includes(cell.data.item.id_shift_const_status) && cell.data.item.id_applicant_const_status == 2 && (user_role < 3 || cell.data.item.can_user_edit)" @click="confirmPerson(cell.data.item)">{{trans('sh-confirmed-by-client',220)}}</b-dropdown-item>

                <!--REJECT PERSON WHEN REGISTEREN-->
                <b-dropdown-item v-if="[3].includes(cell.data.item.id_shift_const_status) && [1].includes(cell.data.item.id_applicant_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, null)">{{trans('decline',175)}}</b-dropdown-item>                   

                <!--REJECT WHEN WATCH OP CLANT -->
                <b-dropdown-item v-if="[4].includes(cell.data.item.id_shift_const_status) && cell.data.item.id_applicant_const_status == 2 && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, null)">{{trans('sh-rejected-by-client',220)}}</b-dropdown-item>

                <!--REJECT BY FLEX -->
                <b-dropdown-item v-if="[15].includes(cell.data.item.id_shift_const_status) && cell.data.item.id_applicant_const_status == 6 && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, null)">{{trans('sh-rejected-by-flexworker',220)}}</b-dropdown-item>

                <!--SET FUNCTION-->
                <b-dropdown-item v-if="cell.data.item.id_function_data_main == null" @click="setFunction(cell.data.item)">{{trans('cp-select-function',192)}}</b-dropdown-item> 
                


                <!--<b-dropdown-item v-if="[4, 15].includes(cell.data.item.id_shift_const_status) && cell.data.item.id_applicant_const_status == 2 && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, null)">{{trans('sh-rejected-by-client',220)}}</b-dropdown-item>-->
                <!--<b-dropdown-item v-if="cell.data.item.id_shift_const_status == 5 && cell.data.item.id_applicant_const_status == 3 && differenceDays(cell.data.item.db_shift_date_from) > -4  && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, 7)">{{trans('sh-unsubscribe-flexworker',220)}}</b-dropdown-item>--> <!-- jezeli mniej 4 dni do rozpoczecia zmiana status na 7 -->
                <!--<b-dropdown-item v-if="cell.data.item.id_shift_const_status == 5 && cell.data.item.id_applicant_const_status == 3 && differenceDays(cell.data.item.db_shift_date_from) <= -4 && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item, 11)">{{trans('sh-replacement',220)}}</b-dropdown-item>--> <!-- jezeli jest wiecej niz 4 dni do rozpoczenia to reject fukcja-->

                <!--<b-dropdown-divider v-if="![2, 13, 17].includes(cell.data.item.id_shift_const_status)"></b-dropdown-divider>

                <b-dropdown-item v-if="![2, 13, 17].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="cancelShift(cell.data.item, 13)">{{trans('sh-service-withdrawn-by-customer',220)}}</b-dropdown-item>
                <b-dropdown-item v-if="![2, 13, 17].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="cancelShift(cell.data.item, 2)">{{trans('sh-service-lost-to-competitor',220)}}</b-dropdown-item>
                <b-dropdown-item v-if="![2, 13, 17].includes(cell.data.item.id_shift_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="cancelShift(cell.data.item, 17)">{{trans('sh-remove-service',220)}}</b-dropdown-item>

                -->
            </template>


        </app-view-table>
        <registrations-shifts-suggest-client v-if="mSuggestClient.show" :show.sync="mSuggestClient.show" :args.sync="mSuggestClient.args" :result.sync="mSuggestClient.result" :return.sync="mSuggestClient.return" />
        <shifts-profile-registration-set-function v-if="mSetFunction.show" :show.sync="mSetFunction.show" :args.sync="mSetFunction.args" :result.sync="mSetFunction.result" :return.sync="mSetFunction.return" />
    </div>  
</template>
<script>
 
// #ID _03-02.00 - PMI 2022-01-26

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';
import RegistrationsShiftsSuggestClient from '../../Shifts/Profile/Registration/ShiftsProfileRegistrationSuggestClient.vue';
import ShiftsProfileRegistrationSetFunction from '../../Shifts/Profile/Registration/ShiftsProfileRegistrationSetFunction.vue';
import moment from 'moment'


  export default {

    components: {
        RegistrationsShiftsSuggestClient,
        ShiftsProfileRegistrationSetFunction
    },

    props: ["loading", "total", "refresh", "search", "type", "listType", "queueTotal", "listTypeChanged"],
    
    mounted() {
        this.filters.list_type = this.listType;
        this.filters.search = this.search;

        this.onCreateThis();
    },

    computed: {
        cRefresh: {
            get() { return this.refresh },
            set(value) {this.$emit('update:refresh', value) }
        },
        cLoading: {
            get() { return this.loading },
            set(value) {this.$emit('update:loading', value) }
        },
        cType: {
            get() { return this.type },
            set(value) {this.$emit('update:type', value) }
        },

        cListType: {
            get() { return this.listType },
            set(value) {this.$emit('update:listType', value) }
        },
        cListTypeChanged: {
            get() { return this.listTypeChanged },
            set(value) {this.$emit('update:listTypeChanged', value) }
        },
        
        cTotal: {
            get() { return this.total },
            set(value) {this.$emit('update:total', value) }
        },

        cQueueTotal: {
            get() { return this.queueTotal },
            set(value) {this.$emit('update:queueTotal', value) }
        },
        
    },

    data() {
        return {
            customColumns: ["function_person", "client_location_name","client_department_name", "function_shift"],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 1,
                        changed: 0
                },
                filtering: {                        
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: null, widthClass:'app-width-min-350'},
                    client_departments: { type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass:'app-width-min-350'},
                    function_person: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    function_shift: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    types: {type: 'list', changed: 0, values: [], function: "getPersonContractList", parameters: null, widthClass:'app-width-min-250'},                 
                    applicant_statuses: { type: 'list', changed: 0, values: [], function: "getShiftStatusRegistration", parameters: null, widthClass:'app-width-min-350'},
                    locations: { type: 'list', changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-350'},
                },
                
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                list_type: 1,
                type: 1,

            },
            
            fields: [
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "160px" }, visible: true, filtering: false},
                { key: "function_person", label: this.trans('function',3), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "function_person"},
                { key: "type_name", label: this.trans('placement-contract',223), thStyle: { "min-width": "110px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "types"},
                { key: "client_location_name", label: this.trans('location',3), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: "client_locations"},
                { key: "client_department_name", label: this.trans('department',182), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: "client_departments"},
                { key: "week_date", label: this.trans('week',3), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false},
                { key: "shift_date", label: this.trans('date',182), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false},
                { key: "shift_start_time", label: this.trans('planning-begin',209), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false},
                { key: "shift_end_time", label: this.trans('planning-end',209), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false},
                { key: "function_shift", label: this.trans('function',3), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "80px", "max-width": "80px"}, visible: true, filtering: true, filtering_name: "function_shift"},
                { key: "office_name", label: this.trans('branch',182), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "locations"},              
                { key: "applicant_status_name", label: this.trans('status',3), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "applicant_statuses"},
                { key: "entry_date", label: this.trans('planning-signed-in-on',209), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false},
                { key: "number_of_shifts_client", label: this.trans('planning-number-of-assignments-client',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true},
                { key: "number_of_shifts_department", label: this.trans('planning-number-of-assignments-department',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true},
            ],

            items: [],
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            mSuggestClient: { show: false, args: { 
                id_shift_ref_applicants: null,
                id_shift_data_main: null,
                id_client_data_main: null,
                id_person_data_main: null,
                registration_tool: null,
                send_notification: null,
                client_confirmation: null,
                filters: null,
                return_type: null
                }
            },

            mSetFunction: { show: false, args: { 
                                                id_shift_data_main: null,
                                                id_person_data_main: null,
                                                id_shift_ref_applicants: null,
                                                filters: null,
                                                return_type: null
                                                },
                            result: 0,
                            return: null },
        }
    },

    methods: {
        
        getList() {
           this.cLoading = true;
           axios
                .post("planning/registrations/getShifts", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                 
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                    this.cQueueTotal = res.data.queue_total;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.cTotal = this.items.total;
                    this.cLoading = false;
                });
        },

        addPerson(item){
            
            let ids_shift_data_main = [item.id_shift_data_main];
                
            this.$router.push({ name: "planning-shifts-add-person", params: { ids_shift_data_main: ids_shift_data_main,
                                                                              start_date: item.shift_date,
                                                                              returnType: "registrations_list",
                                                                              returnIdType: null }});
        },

        confirmPerson(item){
            
             this.alertClass.openAlertConfirmation(this.trans('sh-confirm-employee-confirmation',221, {personNameApplicant : item.candidate_person_name} )).then(res => {

                if(res == true){

                    this.cLoading = true;

                    axios
                        .post('planning/shifts/person/confirm', {
                            id_shift_data_main: item.id_shift_data_main,
                            id_shift_ref_applicants: item.id_shift_ref_applicants,
                            id_person_data_main: item.id_person_data_main,
                            registration_tool: item.registration_tool,
                            send_notification: item.send_notification,
                            return_type: "registrations_list",
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                            this.cQueueTotal = res.data.queue_total;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        });

                }

             });
        },

        rejectPerson(item, idShiftConstStatus){

            let alertMessage = "";
            let postData = null;

            if(item.id_shift_const_status == 5){//bevestigd
                alertMessage = this.trans('confirm-message',180);

                postData = {
                                id_shift_data_main: item.id_shift_data_main,
                                ids_shift_ref_applicants: JSON.stringify([item.id_shift_ref_applicants]),
                                return_type: "registrations_list",
                                id_shift_const_status: idShiftConstStatus,
                                filters: JSON.stringify(this.filters),
                            };
            }else{
                alertMessage = this.trans('sh-reject-applicant-confirmation',221, {personNameApplicant : item.candidate_person_name});
                postData = {
                                id_shift_data_main: item.id_shift_data_main,
                                ids_shift_ref_applicants: JSON.stringify([item.id_shift_ref_applicants]),
                                return_type: "registrations_list",
                                filters: JSON.stringify(this.filters),
                            };
            }

            this.alertClass.openAlertConfirmation(alertMessage).then(res => {

                if(res == true){
                    this.cLoading = true;
                    
                    axios
                        .post('planning/shifts/person/reject', postData)
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                            this.cQueueTotal = res.data.queue_total;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        });
                }

            });

        },

        differenceDays(date){
            var today = moment(moment.now());
            var d = today.diff(moment(date, 'YYYY-MM-DD HH:mm'), 'days');
            return d;
        },

        /*cancelShift: function(item, id_shift_const_status) {
            this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => { 

                if(res == true){

                    this.cancel_loading = true;

                    let post_data = {
                        ids_shift_data_main: JSON.stringify([item.id_shift_data_main]),
                        id_shift_const_status: id_shift_const_status,
                        return_type: "registrations_list"
                    };

                    axios
                        .post("planning/shifts/cancel", post_data)
                        .then((res) => {                 
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                            this.cQueueTotal = res.data.queue_total;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        });
                }

             });
        },*/

        goToShiftProfile: function(selectedRows) {
            if(selectedRows.length != 0){
                this.$router.push({ name: "planning-shifts-profile", params: { id_shift_data_main: selectedRows[0].id_shift_data_main } });
            }
        },

        suggestClient(item){

            this.mSuggestClient.args.id_shift_ref_applicants = item.id_shift_ref_applicants;
            this.mSuggestClient.args.id_shift_data_main = item.id_shift_data_main;
            this.mSuggestClient.args.client_confirmation = item.client_confirmation;
            this.mSuggestClient.args.registration_tool = item.registration_tool;
            this.mSuggestClient.args.send_notification = item.send_notification;
            this.mSuggestClient.args.filters = JSON.stringify(this.filters);
            this.mSuggestClient.args.return_type = 'registrations_list';
            this.mSuggestClient.show = true;

        },

        setFunction(item){
            
            this.mSetFunction.args.id_shift_data_main = item.id_shift_data_main;
            this.mSetFunction.args.id_person_data_main = item.id_person_data_main;
            this.mSetFunction.args.id_shift_ref_applicants = item.id_shift_ref_applicants;
            this.mSetFunction.args.filters = this.filters;
            this.mSetFunction.args.return_type = "registrations_list";
            this.mSetFunction.show = true;
        },
        

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 7);
        },

        async loadSettings() {
            if(this.listTypeChanged == false){
                var getData = JSON.parse(localStorage.getItem('userSettings'));

                if(getData == undefined || getData.registrationList == undefined){
                    await this.userSettings.saveSettings(this.filters, 7);
                    getData = JSON.parse(localStorage.getItem('userSettings'));
                }

                var sFilters = getData.registrationList;
                this.filters.filtering = sFilters.filtering;
                this.filters.sorting = sFilters.sorting;
                this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10; 
                this.cListTypeChanged = false;
            }
            this.saveSettings();
        },

        onCreateThis() { 
       
            this.loadSettings();
            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

             this.$watch('listType', function(newVal, oldVal) {
                 
                if (!this.cLoading) {
                    this.filters.list_type = this.cListType;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.client_locations.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.client_departments.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.function_person.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.function_shift.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.applicant_statuses.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });
            
            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('search', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.filters.search = this.search;
                    this.getList();
                } 
            });
           
            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('mChange.result', function(newVal, oldVal) {
                    if(this.mChange.result == 1){
                        this.mChange.result = 0;
                        this.getList();
                    }
            });

            this.$watch('mPersonAdd.result', function(newVal, oldVal) {
                    if(this.mPersonAdd.result == 1){
                        this.button_visible = false;
                        this.mPersonAdd.result = 0;
                    }
            });

            this.$watch('mSuggestClient.result', function(newVal, oldVal) {
                    if(this.mSuggestClient.result == 1){
                        this.items = this.mSuggestClient.return.items;
                        //this.filters.page = this.mSuggestClient.return.page;
                        this.cQueueTotal = this.mSuggestClient.return.queue_total;
                    }
            });


            this.$watch('mSetFunction.result', function(newVal, oldVal) {
                    if(this.mSetFunction.result == 1){
                        this.items = this.mSetFunction.return.items;
                        this.cQueueTotal = this.mSetFunction.return.queue_total;
                    }
            });


        },

    },
  };
</script>
<style scoped>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}
.tooltip{
    opacity: 1 !important;
}

</style>
