<template>
    <div class="mt-2">
        <app-view-table :isTopBar="false" :isRowHover="true" :filters.sync="filters" :items="items" :fields="fields"  v-on:onRowSelected="goToAssigmentProfile" :customColumns="customColumns" class="table-selectable" :key="refresh">
            

            <template v-slot:function_person="cell">
                <span class="app-ellips" :title="cell.data.item.function_person_full" > {{cell.data.item.function_person}} </span>
            </template>

            <template v-slot:client_location_name="cell">
                <span class="app-ellips" :title="cell.data.item.client_location_name_full" > {{cell.data.item.client_location_name}} </span>
            </template>

            <template v-slot:client_department_name="cell">
                <span class="app-ellips" :title="cell.data.item.client_department_name_full" > {{cell.data.item.client_department_name}} </span>
            </template>

            <template v-slot:function_assigment="cell">
                <span class="app-ellips" :title="cell.data.item.function_assignment_full" > {{cell.data.item.function_assigment}} </span>
            </template>

            <template v-slot:menu-items="cell">    

                <!--OPEN ASSIGNMENT CARD-->
                <b-dropdown-item :to="{ name: 'planning-assignments-profile', params: { id_assignment_data_main: cell.data.item.id_assignment_data_main }}">{{trans('pl-to-secondment', 210)}}</b-dropdown-item>
                <!--APP PERSON-->
                <b-dropdown-item v-if="[1, 8].includes(cell.data.item.id_assignment_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="addPerson(cell.data.item)">{{trans('cp-register-flexworker',189)}}</b-dropdown-item>
                
                <!--SUGGEST PERSON TO CLIENT-->
                <b-dropdown-item v-if="[8, 2].includes(cell.data.item.id_assignment_const_status) && [1].includes(cell.data.item.id_applicant_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="suggestClient(cell.data.item, false)">{{trans('sh-offer-to-client',220)}}</b-dropdown-item>

                <!--CONFIRM PERSON-->
                <b-dropdown-item v-if="cell.data.item.id_assignment_const_status == 2 && cell.data.item.id_applicant_const_status == 2 && (user_role < 3 || cell.data.item.can_user_edit)" @click="confirmPerson(cell.data.item)">{{trans('sh-confirmed-by-client',220)}}</b-dropdown-item>
                
                <!--REJECT PERSON WHEN REGISTEREN-->
                <b-dropdown-item v-if="[8].includes(cell.data.item.id_assignment_const_status) && [1].includes(cell.data.item.id_applicant_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item)">{{trans('decline',175)}}</b-dropdown-item>
                
                <!--REJECT WHEN WATCH OP CLANT -->
                <b-dropdown-item v-if="[2].includes(cell.data.item.id_assignment_const_status) && [2].includes(cell.data.item.id_applicant_const_status) && (user_role < 3 || cell.data.item.can_user_edit)" @click="rejectPerson(cell.data.item)">{{trans('sh-rejected-by-client',220)}}</b-dropdown-item>
                
                <!--<b-dropdown-item v-if="(cell.data.item.id_assignment_const_status != 6 && cell.data.item.id_assignment_const_status != 7) && (user_role < 3 || cell.data.item.can_user_edit)" @click="cancelAssignment(cell.data.item)">Afwijzen door planner</b-dropdown-item>-->

            </template>

           <template v-slot:thead-top>
                <b-th class="text-center" colspan="4">{{trans('planning-fw-data',173)}}</b-th>
                <b-th class="text-center app-table-bg-light" colspan="6">{{trans('planning-secondment-data',173)}}</b-th>
                <b-th class="text-center" colspan="5"></b-th>
                
            </template>

        </app-view-table>
       
    </div>  
</template>
<script>
 
// #ID _03-02.00 - PMI 2022-01-26

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';


  export default {


    props: ["selected", "loading", "total", "refresh", "search", "type", "listType", "listTypeChanged"],
    

    mounted() {
      this.filters.list_type = this.listType;
      this.filters.search = this.search;
      this.onCreateThis();
     
    },

    computed: {
        cRefresh: {
            get() { return this.refresh },
            set(value) {this.$emit('update:refresh', value) }
        },
        cLoading: {
            get() { return this.loading },
            set(value) {this.$emit('update:loading', value) }
        },
        cType: {
            get() { return this.type },
            set(value) {this.$emit('update:type', value) }
        },
        cListType: {
            get() { return this.listType },
            set(value) {this.$emit('update:listType', value) }
        },
        cListTypeChanged: {
            get() { return this.listTypeChanged },
            set(value) {this.$emit('update:listTypeChanged', value) }
        },
        
        cTotal: {
            get() { return this.total },
            set(value) {this.$emit('update:total', value) }
        },

        
    },

    data() {
        return {
            userSettings: new UserSettings(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            customColumns: ["function_person", "client_location_name","client_department_name", "function_assigment"],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 1,
                        changed: 0
                },
                filtering: {                        
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: null, widthClass:'app-width-min-350'},
                    client_departments: { type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass:'app-width-min-350'},
                    function_person: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    function_assignment: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    types: {type: 'list', changed: 0, values: [], function: "getPersonContractList", parameters: null, widthClass:'app-width-min-250'},                 
                    applicant_statuses: { type: 'list', changed: 0, values: [], function: "getAssignmentStatusRegistration", parameters: null, widthClass:'app-width-min-350'},
                    locations: { type: 'list', changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-350'},
                },
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                list_type: 1,
                type: 2,
            },

            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", visible: true  },
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "160px", "max-width": "160px" },  visible: true, filtering: false},
                { key: "function_person", label: this.trans('function',3), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "function_person"},
                { key: "type_name", label: this.trans('placement-contract',223), thStyle: { "min-width": "110px", "max-width": "120px" }, visible: true,  filtering: true, filtering_name: "types"},
                { key: "client_location_name", label: this.trans('location',3), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: "client_locations"},
                { key: "client_department_name", label: this.trans('department',182), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true,  filtering: true, filtering_name: "client_departments"},
                { key: "assignment_start_time",  label: this.trans('date-start',3), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: false},
                { key: "assignment_end_time",  label: this.trans('date-end',3), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: false},
                { key: "function_assignment", label: this.trans('function',3), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "100px"}, visible: true, filtering: true, filtering_name: "function_assignment"},
                { key: "office_name", label: this.trans('branch',182), thClass:"app-table-bg-light", tdClass:"app-table-bg-light", thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "locations"},               
                { key: "applicant_status_name", label: this.trans('status',3), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: true, filtering_name: "applicant_statuses"},
                { key: "entry_date", label: this.trans('planning-signed-in-on',209), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false},
                { key: "number_of_assignments_client", label: this.trans('planning-number-of-assignments-client',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true},
                { key: "number_of_assignments_department", label: this.trans('planning-number-of-assignments-department',209), thStyle: { "min-width": "80px", "max-width": "80px" }, visible: true},
            ],
            items: [],
            alertClass: new AppAlerts(),
        };
    },

    methods: {
        
        getList() {
           this.cLoading = true;
           axios
                .post("planning/registrations/getAssignments", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                 
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.cTotal = this.items.total;
                    this.cLoading = false;
                });
        },

        addPerson(item){
            
            let ids_assignment_data_main = [item.id_assignment_data_main];
            this.$router.push({ name: "planning-assignments-add-person", params: { ids_assignment_data_main: ids_assignment_data_main,
                                                                              start_date: item.assignment_start_time,
                                                                              returnType: "registrations_list",
                                                                              returnIdType: null }});

        },

        confirmPerson(item){
            
             this.alertClass.openAlertConfirmation(this.trans('sh-confirm-employee-confirmation',221, {personNameApplicant : item.candidate_person_name} )).then(res => {

                if(res == true){
                    this.cLoading = true;

                    axios
                        .post('planning/assignments/person/confirm', {
                            id_assignment_data_main: item.id_assignment_data_main,
                            id_assignment_ref_applicants: item.id_assignment_ref_applicants,
                            id_person_data_main: item.id_person_data_main,
                            return_type: "registrations_list",
                            filters: JSON.stringify(this.filters)
                        })
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                            this.cQueueTotal = res.data.queue_total;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        });

                }

             }); 
        },

        rejectPerson(item){

            this.alertClass.openAlertConfirmation(this.trans('sh-reject-applicant-confirmation',221, {personNameApplicant : item.candidate_person_name})).then(res => {
                if(res == true){
                    
                    this.cLoading = true;

                    axios
                        .post('planning/assignments/person/reject', {
                            id_assignment_data_main: item.id_assignment_data_main,
                            ids_assignment_ref_applicants: JSON.stringify([item.id_assignment_ref_applicants]),
                            return_type: 'registrations_list',
                            filters: JSON.stringify(this.filters)
                        })
                        .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                            this.cQueueTotal = res.data.queue_total;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        })

                }
            }); 

        },

        suggestClient(data, multi){

            let show_document_alert = false;
            let person_names = "";
            let ids = [];

            if(multi){
                ids = this.items.list.filter(function (value) {
                                                                                    return value.selected == true; }).map(a => a.id_assignment_ref_applicants);
                
                let persons = this.items.list.filter(function (value) {
                                                                            return value.selected == true && value.applicant_type == 1 && (value.id_document_data_main == null || value.id_document_data_main == undefined)
                                                                        }).map(a => a.candidate_person_name);
                
                if(persons.length > 0){
                    show_document_alert = true;

                    persons.forEach(item => {
                        person_names += item + ', '
                    });

                    person_names = person_names.slice(0, -2);
                }
                    
            }else{
                ids = [data.id_assignment_ref_applicants];
                
                if(data.applicant_type == 1 && (data.id_document_data_main == null || data.id_document_data_main == undefined)){
                    show_document_alert = true;
                    person_names = data.candidate_person_name;
                }
            }

            if(show_document_alert){
                this.alertClass.openAlertWarning(this.trans('as-flexworker-not-have-cv',216,{person_names : person_names})).then(res => {})
            }else{

                var message = this.trans('as-send-secondemt-to-organisation-confirmation',216);
                this.alertClass.openAlertConfirmation(message).then(res => {

                    if(res == true){
                        this.cLoading = true;

                        axios
                            .post('planning/assignments/person/suggestToClient', {
                                ids_assignment_ref_applicants: JSON.stringify(ids),
                                id_assignment_data_main: data.id_assignment_data_main,
                                filters: JSON.stringify(this.filters),
                                return_type: 'registrations_list'
                            })
                            .then((res) => {
                                this.filters.page = res.data.page;
                                this.items = res.data.items;
                                this.cQueueTotal = res.data.queue_total;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.cTotal = this.items.total;
                                this.cLoading = false;
                            })

                    }
                }); 

            }
                
            },

        /*cancelAssignment: function(item) {
            
            this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => { 

                if(res == true){

                    this.cLoading = true;

                    let post_data = {
                        ids_assignment_data_main: JSON.stringify([item.id_assignment_data_main]),
                        return_type: "registrations_list",
                        filters: JSON.stringify(this.filters)
                    };

                    axios
                        .post("planning/assignments/cancel", post_data)
                        .then((res) => {                 
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                            this.cQueueTotal = res.data.queue_total;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.cTotal = this.items.total;
                            this.cLoading = false;
                        });
                }

             });
        },*/

        goToAssigmentProfile: function(selectedRows) {
            if(selectedRows.length != 0){
                this.$router.push({ name: "planning-assignments-profile", params: { id_assignment_data_main: selectedRows[0].id_assignment_data_main } });
            }
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 7);
        },

       async loadSettings() {
            if(this.listTypeChanged == false){
                var getData = JSON.parse(localStorage.getItem('userSettings'));

                if(getData == undefined || getData.registrationList == undefined){
                    await this.userSettings.saveSettings(this.filters, 7);
                    getData = JSON.parse(localStorage.getItem('userSettings'));
                }

                var sFilters = getData.registrationList;
                this.filters.filtering = sFilters.filtering;
                this.filters.sorting = sFilters.sorting;
                this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10; 
                this.cListTypeChanged = false;
            }
            this.saveSettings();
        },

        onCreateThis() { 
            
            this.loadSettings();
            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

             this.$watch('listType', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.filters.list_type = this.cListType;
                    this.getList();
                    this.saveSettings();
                } 
            });


            this.$watch('filters.filtering.client_locations.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.client_departments.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.function_person.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.function_assignment.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.applicant_statuses.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            
            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                } 
            });

            this.$watch('search', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.filters.search = this.search;
                    this.getList();
                } 
            });
           
            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoading) {
                    this.getList();
                }   
            });

            this.$watch('mChange.result', function(newVal, oldVal) {
                    if(this.mChange.result == 1){
                        this.mChange.result = 0;
                        this.getList();
                    }
            });

            this.$watch('mPersonAdd.result', function(newVal, oldVal) {
                    if(this.mPersonAdd.result == 1){
                        this.button_visible = false;
                        this.mPersonAdd.result = 0;
                    }
            });


        },

    },
  };
</script>
<style>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}
.tooltip{
    opacity: 1 !important;
}
 

</style>
