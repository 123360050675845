 <template>
     <b-card>
        <b-overlay :show="loading" :opacity="0.6">
            <b-row class="app-card-header">
                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height">
                        <div> {{ type == 1 ? trans('registraion',5) : trans('posting',3)}} <span v-if="!loading">({{ total }})</span></div>
                    </div>
                </b-col>

                <b-col class="app-input-applicant-filter-width" >
                    <b-form-input type="text" v-model="search" :lazy="true" class="form-control app-input" :class="{'app-input-filled':(search!= null && search.length > 0)}" :placeholder="trans('search',3)" size="sm" />
                </b-col>

                <b-col cols="auto" class="pl-0" end>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 1, 'app-list-button-selected':  type === 1 }" @click="setListType(1)">{{trans('services',3)}}</b-btn>                        
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': type !== 2, 'app-list-button-selected':  type === 2 }" @click="setListType(2)">{{trans('posting',3)}}</b-btn>
                    </b-button-group>                             
                </b-col>

                <b-col class="app-input-employee-filter-width pl-0" end>
                    <app-select v-model="list_type" reduceValue customBody :clearable="false" :selectOnTab="true" :options="filters_list" :selectable="(item) => item.value > 0">
                        <template v-slot:option="option">
                        <hr v-if="option.data.value < 0" class="app-search-modal-separator">    
                        <span v-else>{{ option.data.name }}</span>
                        </template>
                    </app-select>  
                </b-col>
 
                <b-col cols="auto" end class="d-flex align-items-center pl-0">
                    <app-refresh :refresh.sync="refresh" :loading.sync="loading"/>
                </b-col>
            </b-row>

            <registrations-shifts-component v-if="type == 1" :loading.sync="loading" :total.sync="total" :search.sync="search" :refresh.sync="refresh" :listType.sync="list_type" :listTypeChanged="list_type_changed" :type.sync="type" :queueTotal.sync="queue_total"/>
            <registrations-assignments-component v-if="type == 2" :loading.sync="loading" :total.sync="total" :search.sync="search" :refresh.sync="refresh" :listType.sync="list_type" :listTypeChanged="list_type_changed" :type.sync="type" />
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>            
    </b-card>
</template>

<script>

import moment from "moment";
import RegistrationsShiftsComponent from './Shifts/RegistrationsShiftsComponent.vue';
import RegistrationsAssignmentsComponent from './Assignments/RegistrationsAssignmentsComponent.vue';

export default {
  components: { 
      RegistrationsShiftsComponent,
      RegistrationsAssignmentsComponent,
   },

    props:["profile"],

    watch:{

    },

    created(){
         this.getListType();
    },

    data(){
        return {
            loading: false,
            type: 1,
            total: 0,
            list_type: 2,
            search: '',
            avatar_variant: 'secondary',         
            refresh:0,
            queue_total:0,
            list_type_changed: false,
            filters_list: [
                { value: 1, name: this.trans('my-flex',179)},
                { value: 2, name: this.trans('my-clients',3) },
                { value: 3, name: this.trans('my-locations',179) },                
                { value: 4, name: this.trans('planning-all-registrations',173)}
            ],
        }
    },

    methods: {
        setListType(type) {
            this.type = type;
            this.list_type_changed = true;
            this.search = null;
        },

         async getListType(){
            var getData = JSON.parse(localStorage.getItem('userSettings'));

             if(getData != undefined && getData.registrationList != undefined){
                this.type = getData.registrationList.type;
                this.list_type = getData.registrationList.list_type;
             }
         },

         getShiftQueue(){
            this.$router.push({ name: "planning-registrations-shifts-queue"});
         }
    }

};
</script>

<style scoped>

</style>